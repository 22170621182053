<template>
    <div>
        <section class="intro home">
            <div class="inner">
                <div class="intro_home_content grid grid_pad grid_wrap">
                    <div class="grid_col grid_col_1_2">
                        <div class="grid_col_box">
                            <div class="main_heading">
                                <h1>Productos alternativos para gente muy normal</h1>
                            </div>
                            <div class="grid product_categories_menu">
                                <div class="grid_col">
                                    <router-link to="/productos" class="category_fast_link grid_col_block">
                                        <span class="fast_link_text">Productos</span>
                                        <span class="got_to_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/></svg></span>
                                        <span class="animated_bg bg_01"></span>
                                    </router-link>
                                </div>
                                <div class="grid_col">
                                    <router-link to="/artistas" class="category_fast_link grid_col_block">
                                        <span class="fast_link_text">Artistas</span>
                                        <span class="got_to_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/></svg></span>
                                        <span class="animated_bg bg_02"></span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="grid_col grid_col_1_2">
                        <div class="grid_col_box">
                            <div class="slider_module">
                                <VitySlide 
                                    :dat="slider"
                                    @click="(obj) => {$router.push(`/productos/${obj}`)}"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="featured">
            <div class="inner">
                <div class="grid grid_pad banner_home_list">
                    <div class="grid_col grid_col_1_2">
                        <div class="banner_home grid_col_box">
                            <a href=""><img src="@/assets/images/featured_banner_01.jpg"/></a>
                        </div>
                    </div>
                    <div class="grid_col grid_col_1_2">
                        <div class="banner_home grid_col_box">
                            <a href=""><img src="@/assets/images/featured_banner_02.jpg"/></a>
                        </div>
                    </div>
                    <div class="grid_col grid_col_1_2">
                        <div class="banner_home grid_col_box">
                            <a href=""><img src="@/assets/images/featured_banner_02.jpg"/></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="products">
            <div class="inner height_inner">
                <header class="section_header header_flex">
                    <h2 class="text_xxl text_red text_300">Últimos productos</h2>
                    <router-link to="/productos" class="btn small graphic_right local_color">Ver todos<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"></path></svg></router-link>
                </header>
                <div class="product_list grid__motor grid__motor_col_5">
                    <article class="product_item" v-for="product in lastProducts" :key="product.id">
                        <Producto :product="product"/>
                    </article>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'
import VitySlide from '@/components/VitySlides'
import Producto from '@/components/Producto'

export default {
    name: 'Inicio',
    components:{
        VitySlide,
        Producto,
    },
    data: function(){
        return{
            lastProducts: [],
            destacados: [],
            slider: []
        }
    },
    methods:{
        getLastProducts: function(){
            axios
                .get(process.env.VUE_APP_URL+'products', {
                    params:{
                        'orderBy[column]': 'actived_at',
                        'orderBy[order]': 'desc',
                        limit: 5
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK')
                        this.lastProducts = rs.data;
                })
                .catch(error => this.$parent.error = 'Hubo un error de conexion. intentelo mas tarde o contacte con administracion')
        },
        getFeaturedProducts: function(){
            axios
                .get(process.env.VUE_APP_URL+'products', {
                    params:{
                        featured: 1,
                        'orderBy[column]': 'orden',
                        'orderBy[order]': 'asc'
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.slider = [];
                        rs.data.forEach((obj) => {
                            this.slider.push({
                                title: obj.product.name,
                                subTitle: obj.product.description,
                                img: obj.product.img,
                                returnData: obj.product.url
                            });
                        });
                    }
                })
                .catch(error => this.$parent.error = 'Hubo un error de conexion. intentelo mas tarde o contacte con administracion')
        },
    },
    created: function(){
        this.getLastProducts();
        this.getFeaturedProducts();
    },
}
</script>